import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./vista-evaluaciones-multiples-compartidas.component.html"
})
export class VistaEvaluacionesMultiplesCompartidasComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.evaluaciones.vista_evaluaciones_multiples_compartidas")
    }
}
