<div class="main">
    <div class="contenedor-columnas">
        <div
            class="columna-evaluaciones"
            *ngIf="evaluacionInstanciasByEvaluacionMultipleId && evaluacionMultipleInstanciasByEvaluacionMultipleId"
        >
            <ng-container *ngFor="let evaluacionMultiple of evaluacionMultiples">
                <unlocked-instrumento-cb-row
                    [evaluacionMultiple]="evaluacionMultiple"
                    [evaluacionMultipleInstancia]="
                        evaluacionMultipleInstanciasByEvaluacionMultipleId[evaluacionMultiple.id]
                    "
                    [evaluacionInstancias]="evaluacionInstanciasByEvaluacionMultipleId[evaluacionMultiple.id] || []"
                    [locked]="false"
                    class="evaluacion"
                ></unlocked-instrumento-cb-row>
            </ng-container>
        </div>
    </div>
</div>
