import { Component, OnInit, ChangeDetectorRef, HostBinding, Input } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import {
    EvaluacionMultiples,
    EvaluacionMultiple,
    EvaluacionInstancias,
    EvaluacionMultipleInstancia,
    EvaluacionMultipleInstancias,
    EvaluacionTiempos,
    GrupoRecursos,
    EvaluacionInstancia,
    EvaluacionMultipleUsuario,
    EvaluacionMultipleUsuarios
} from "@puntaje/puntaje/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    selector: "vista-evaluaciones-multiples-compartidas",
    templateUrl: "./vista-evaluaciones-multiples-compartidas.component.html",
    styleUrls: ["./vista-evaluaciones-multiples-compartidas.component.scss"]
})
export class VistaEvaluacionesMultiplesCompartidas implements OnInit {
    usuarioId: string
    @Input() listaView: boolean = false

    evaluacionMultiples: EvaluacionMultiple[]
    evaluacionMultipleInstanciasByEvaluacionMultipleId: { [id: number]: EvaluacionMultipleInstancia }
    evaluacionInstanciasByEvaluacionMultipleId: { [id: number]: EvaluacionInstancia[] }
    lockedByEvaluacionMultipleId: { [id: number]: boolean } = {}

    evaluacionInstancias: EvaluacionInstancia[]
    evaluacionMultipleInstancias: EvaluacionMultipleInstancia[]
    @HostBinding("class") innerClass = "showcasebox_style_1"

    constructor(
        protected authService: AuthService,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        protected evaluacionMultipleInstanciasService: EvaluacionMultipleInstancias,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected cdr: ChangeDetectorRef,
        protected store: Store<State>
    ) {}

    async ngOnInit() {
        const usuarioId = this.authService.getUserData()?.id

        this.evaluacionMultiples = await this.evaluacionMultiplesService.where({
            mios: true,
            render_options: {
                include: {
                    evaluaciones: {
                        include: {
                            instrumento: {
                                include: {
                                    generador_instrumento: "null"
                                }
                            }
                        }
                    },
                    evaluacion_tipo: null
                }
            }
        })

        const evaluacionMultipleInstancias = await this.getEvaluacionMultipleInstancias(
            this.evaluacionMultiples,
            usuarioId
        )

        this.evaluacionMultipleInstanciasByEvaluacionMultipleId = evaluacionMultipleInstancias.reduce((acc, emi) => {
            if (!acc[emi.evaluacion_multiple_id] || acc[emi.evaluacion_multiple_id].nintento < emi.nintento) {
                acc[emi.evaluacion_multiple_id] = emi
            }

            return acc
        }, {})

        const evaluacionInstancias = await this.getEvaluacionInstancias(this.evaluacionMultiples, usuarioId)

        this.evaluacionInstanciasByEvaluacionMultipleId = this.evaluacionMultiples.reduce((acc, em) => {
            const evaluacionInstanciasEvaluaciones = evaluacionInstancias.filter(ei =>
                em.evaluaciones.find(e => e.id === ei.evaluacion_id)
            )
            const evaluacionInstanciaByEvaluacion = evaluacionInstanciasEvaluaciones.reduce((acc, ei) => {
                acc[ei.evaluacion_id] = acc[ei.evaluacion_id] ?? ei
                if (ei.nintento > acc[ei.evaluacion_id].nintento) {
                    acc[ei.evaluacion_id] = ei
                }

                return acc
            }, {} as { [id: number]: EvaluacionInstancia })

            if (Object.keys(evaluacionInstanciaByEvaluacion).length !== 0) {
                acc[em.id] = Object.values(evaluacionInstanciaByEvaluacion)
            }

            return acc
        }, {})

        this.cdr.detectChanges()
    }

    private async getEvaluacionMultipleInstancias(evaluacionMultiples: EvaluacionMultiple[], usuarioId: number) {
        const evaluacionMultipleInstanciasParams = {
            // search: 1,
            // sort_by: "created_at",
            // order: "desc",
            evaluacion_multiple_instancia: {
                evaluacion_multiple_id: evaluacionMultiples.map(em => em.id),
                usuario_id: usuarioId
            },

            include: "[evaluacion_instancias:[evaluacion:[instrumento:generador_instrumento]]]",
            methods: "[calificacion]"
        }

        const evaluacionMultipleInstancias = await this.evaluacionMultipleInstanciasService.where(
            evaluacionMultipleInstanciasParams
        )

        return evaluacionMultipleInstancias
    }

    private async getEvaluacionInstancias(evaluacionMultiples: EvaluacionMultiple[], usuarioId: number) {
        const evaluacionInstanciasParams = {
            evaluacion_instancia: {
                evaluacion_id: evaluacionMultiples.map(em => em.evaluaciones.map(e => e.id)).flat(),
                usuario_id: usuarioId
            },
            include: "[evaluacion:[instrumento:generador_instrumento]]"
        }

        const evaluacionInstancias = await this.evaluacionInstanciasService.where(evaluacionInstanciasParams)

        return evaluacionInstancias
    }
}
